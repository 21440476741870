import axios from "axios";
import React, { useEffect, useState } from "react";
import "./blogPage.css";
import "./blogResponsiveness.css";
import { IoArrowRedoCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Footer } from "../../../src/footer";
import { NavBar } from "../../navBar";

export default function Blog() {
    const [postData, setPostData] = useState();
    const [filters, setFilters] = useState();
    const [canDisplay, setCanDisplay] = useState(false);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [featured, setFeatured] = useState();

    useEffect(() => {
        axios
            .get(
                'https://z2v7up70.api.sanity.io/v1/data/query/production?query=*[_type == "blog"] | order(datePublished desc){title, "imageUrl": image.asset->url, categories[]->{"categoryName": categoryName, "visible": visible}, datePublished, description, slug, content}'
            )
            .then((res) => {
                if (res.data.result.length > 0) {
                    setFeatured(res.data.result[0]);
                    setPostData(res.data.result.slice(1));
                    setFilteredPosts(res.data.result.slice(1));
                    setCanDisplay(true);
                } else {
                    setCanDisplay(false);
                }
            })
            .catch((err) => console.log(err));

        axios
            .get(
                'https://z2v7up70.api.sanity.io/v1/data/query/production?query=*[_type== "categories"]{"categoryName": categoryName, "visible": visible}'
            )
            .then((res) => {
                setFilters(res.data.result);
            })
            .catch((err) => console.log(err));
    }, []);

    const filterPosts = (categoryName) => {
        console.log(postData);
        if (categoryName === "all") return setFilteredPosts(postData);
        setFilteredPosts(
            postData.filter(
                (post) => post.categories[0].categoryName === categoryName
            )
        );
    };

    return (
        <div>
            <NavBar />
            <div className='mainContainerBlog'>
                <div className='featured'>
                    {canDisplay ? (
                        <FeaturedPost
                            data={featured}
                            slug={featured.slug.current}
                            img={featured.imageUrl}
                            title={featured.title}
                            description={featured.description}
                            tag={featured.categories[0].categoryName}
                            date={featured.datePublished}
                        />
                    ) : (
                        <BlankFeatured />
                    )}
                </div>
                <div className='recent'>
                    <div className='titleContainer'>
                        <h3>Recent blog posts</h3>
                        <div className='filterContainer'>
                            <h5>Filter posts by:</h5>
                            <Filter
                                display={canDisplay}
                                data={filters}
                                handlechange={filterPosts}
                            />
                        </div>
                    </div>
                    <div className='recentPostsContainer'>
                        {canDisplay ? (
                            filteredPosts.map((post) => {
                                return (
                                    <RecentPost
                                        data={post}
                                        slug={post.slug.current}
                                        key={post.title}
                                        img={post.imageUrl}
                                        title={post.title}
                                        description={post.description}
                                        date={post.datePublished}
                                        tag={post.categories[0].categoryName}
                                    />
                                );
                            })
                        ) : (
                            <>
                                <BlankRecent />
                                <BlankRecent />
                                <BlankRecent />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

function FeaturedPost({ slug, date, img, tag, title, description, data }) {
    return (
        <Link
            className='featuredLink'
            to={`/blog/${slug}`}
            state={{ post: data }}
        >
            <div className='featuredPost'>
                <div className='featuredContent'>
                    <div className='parentTagContainer'>
                        <div className='tag category'>
                            <p>{tag}</p>
                        </div>
                        <div className='tag new'>
                            <p>New!</p>
                        </div>
                    </div>
                    <h1 className='featuredTitle'>{title}</h1>
                    <p className='featuredDescription'>{description}</p>
                    <p>{date}</p>
                </div>
                <img className='featuredImg' src={img} alt='featured post' />
                <div className='arrow'>
                    <IoArrowRedoCircleOutline size={100} />
                </div>
            </div>
        </Link>
    );
}

function BlankFeatured() {
    return <div className='blankF'></div>;
}

function RecentPost({ slug, data, title, img, tag, description, date }) {
    return (
        <Link to={`/blog/${slug}`} state={{ post: data }}>
            <div className='recentPost' key={title}>
                <div className='imageContainer'>
                    <img className='recentImg' src={img} alt='recent post' />
                </div>
                <div className='recentContent'>
                    <div className='tag category'>
                        <p>{tag}</p>
                    </div>
                    <h3 className='recentTitle'>{title}</h3>
                    <p className='recentDescr'>{description}</p>
                    <div className='authContainer'>
                        <p className='date'>{date}</p>
                    </div>
                </div>
            </div>
        </Link>
    );
}

function BlankRecent() {
    return <div className='blank'></div>;
}

function Filter({ display, data, handlechange }) {
    return (
        <div>
            <select
                name='filter'
                id='filter'
                onChange={(e) => handlechange(e.target.value)}
            >
                <option value='all'>All</option>
                {display ? (
                    data.map((tag) => {
                        if (tag.visible) {
                            return (
                                <option
                                    value={tag.categoryName}
                                    key={tag.categoryName}
                                >
                                    {tag.categoryName}
                                </option>
                            );
                        }
                    })
                ) : (
                    <option>Loading...</option>
                )}
            </select>
        </div>
    );
}
