import React from "react";
import { Button } from "react-bootstrap";
import "./CTA.css";

export default function CTA(props) {
    return (
        <div
            className={"ctaParent " + props.flex}
            style={{ backgroundImage: `url(${props.img})` }}
        >
            <div className={"gradient " + props.gradient}></div>
            <div className={"ctaContent " + props.alignment}>
                <h1 className='fw-normal text-white'>{props.title}</h1>
                <hr className='border-1 border-light opacity-100 mb-3 mt-2' />
                <h4 className='fw-light text-white mb-3'>{props.subtitle}</h4>
                <a href={props.href}>
                    <Button className='btn-dark' size='lg'>
                        Get Started
                    </Button>
                </a>
            </div>
        </div>
    );
}
