import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import "../assets/css/skillbridge.css";
import HeroImage from "../assets/background_image/cissp-background.jpg";
import "bootstrap/dist/css/bootstrap.css";
import Image from 'react-bootstrap/Image';
import { Footer } from "../footer";
import {NavBar} from "../navBar"


export default function SkillBridge () {
	useEffect(() => {
		window.scrollTo(0, 0);
		}, []);

  return (
    <>
    	<NavBar />
	<section className="bg-white-06">
		<section 
			className="heroImageSkillbridge " 
			style={{backgroundImage:`linear-gradient(to top, white, rgba(255, 255, 255, 0.02)),url("${HeroImage}")`}}
			>
        
            <Container className=" text-center">
                <Row>
                    <Col className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 heroText">
                        <h1 className="fw-bold m-0" > Federal Cybersecurity Professional Program </h1>
                        <h2 className="fw-bold m-0" > (Skillbridge) </h2>
                    </Col>
                </Row>
                {/*end row*/}
            </Container>
            {/*end container*/}
        </section>
    </section>
    <hr/>
	<br/>
	<Container>
		<Row className=" center2 center " >
			<h6 className="center text-center"> "Our Skillbridge/CSP program offers Federal Cybersecurity Professional program to Service Members, to gain civilian work experience within the last 180 days of service. It connects them with industrial partners for real-world job experiences."</h6>
		</Row>
		<br/>
		<Row className="center2 center " >
			<a className="center"  href="./download/Federal_Cybersecurity_Professional_Program.pdf" download>
				<Button type="button" className="btn-primary btn-dark">Download Curriculum</Button>
			</a>
			<p className="center text-center" 
                style={{paddingBottom: "40px" , paddingTop: "20px"}} 
                
                >"Below are the certifications that will be provided after completing the program"
            </p>
		</Row>
        {/* HTML calls style="padding-bottom: 40px; padding-top: 20px;"*/}
        <Container >
			<Row className=" g-4 g-lg-5 center">
				<Col md={4} lg={4} className="center content-selection-skillbridge" >
				    <div className="border p-4 content-containe-skillbridge" >
						<Image id="service-flex" src="/images/services_images/thinker.png" alt="CISSP" fluid />
						<h5 className="fw-medium mt-2">CISSP Certification</h5>
						<p>Boost your cybersecurity career with CISSP certification, proving your ability to design and manage exceptional cybersecurity programs. Attaining CISSP not only validates your expertise but also grants ISC2 membership, providing exclusive resources and a network of peers. Showcase your skills, advance your career, secure the desired salary, and join a supportive community of cybersecurity leaders.</p>
					</div>

				</Col>
				<Col md={4} lg={4} className="center content-selection-skillbridge" >
              		<div className="border p-4 content-containe-skillbridge" >
						<Image id="partnership-flex" src="/images/services_images/hand-shake.png" alt="CCSP" fluid />
						<div className="bg-light-theme mb-3"> 
                            <h5 className="fw-medium mt-2">CCSP Certification</h5>
                            <p>Elevate your cloud security expertise with the CCSP certification, demonstrating your proficiency in designing and managing secure cloud environments. As a CCSP, you gain ISC2 membership, unlocking exclusive resources and networking opportunities within the cybersecurity community. Validate your cloud security skills, advance your career, and become part of a supportive network of cybersecurity professionals</p>
                        </div>
					</div>
					
				</Col>
				<Col md={4} lg={4} className="center content-selection-skillbridge" >
              		<div className="border p-4 content-containe-skillbridge" >
						<Image id="zeros-flex" src="/images/services_images/zero_trust.png" alt="zero trust" fluid />
						<div className="bg-light-theme mb-3"> 
                            <h5 className="fw-medium mt-2">Zero Trust Training</h5>
                            <p>VCSG has developed a Zero Trust training program to aid federal agencies in meeting Zero Trust security mandates by FY 2024, as directed by EO 14028 and OMB M-22-09. This comprehensive portfolio includes a ZT Gap Analysis, a strategic roadmap, ZT Workforce Training and Certification, and the Zero Trust Proving Ground CyberRange for piloting and testing Zero Trust solutions. These tools facilitate the transition to a Zero Trust architecture, meeting the goals and deadlines of these Zero Trust Federal Mandates. </p>
                        </div>
					</div>
					
				</Col>
            </Row>
        </Container>
		
	</Container>
	<Footer />
    </>
    )
    }