import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./zeroTrust.css";
import CTA from "../../components/cta/CTA";
import LeftAlignHero from "../../components/LeftAlignHero/LeftAlignHero";
import "./responsiveZeroTrust.css";
import { Footer } from "../../../src/footer";
import { NavBar } from "../../navBar";

export default function ZeroTrust() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const heroData = {
        title: "Unlocking Zero Trust",
        subtitle: "VCSG's innovative approach to seamless compliance",
        img: "./images/zero-trust-images/Hero.jpg",
        imgAlt: "Zero Trust Holographic Screen",
    };

    const slidesOneTwoContent = [
        {
            img: "./images/zero-trust-images/zeroTrustHero.jpg",
            alt: "Zero Trust logo on a computer",
            title: "Zero Trust Compliance Made Easy",
            paragraph1:
                "VCSG stands as a vanguard in ensuring a seamless alignment with the Federal",
            paragraph2:
                "Our approach involves a comprehensive plan that intricately weaves together the strategic steps outlined by NIST SP800-207, judicious cloud migration strategies, and the institutionalization of multi-factor authentication. This ensures not just compliance with mandates but a robust and adaptable Zero Trust architecture.",
        },
        {
            img: "./images/zero-trust-images/f22.jpg",
            alt: "F22 Fighter Jet",
            title: "Military-Proven AI/ML Shield",
            paragraph1:
                "VCSG leverages the power of Artificial Intelligence and Machine Learning (AI/ML) for real-time protection, especially in mission-critical software where the stakes are high.",
            paragraph2:
                "Our AI/ML solution isn't just theoretical; it's battle-tested, having successfully safeguarded the F-22 Raptor. This not only meets the mandates set by Executive Order 14028 but significantly enhances operational efficiency, providing a shield against emerging threats in real-time.",
        },
    ];

    const slidesThreeFourContent = [
        {
            img: "./images/zero-trust-images/heroImage4.jpg",
            alt: "Group of military cyber security proffesional's surounding a computer",
            title: "Robust Security Features",
            paragraph1:
                "The cornerstone of VCSG's security lies in the integration of cutting-edge features that redefine the standards of robust cybersecurity.",
            colTitle1: "Semantic Context Module",
            colContent1:
                "This module, driven by Natural Language Processing (NLP), ensures a contextual understanding of protected applications through Unified Markup Language (UML) sequence diagrams.",
            colTitle2: "Secure OS",
            colContent2:
                " Our operating system (INTEGRITY-178 RTOS) boasts NSA: EAL 6+ High Robustness Common Criteria, achieving the highest security level for an operating system.",
            colTitle3: "NLP-Driven Protection",
            colContent3:
                "Natural Language Processing is a key element, providing a deeper layer of defense by understanding the semantic context of applications.",
        },
        {
            img: "./images/zero-trust-images/lastHero.jpg",
            alt: "Lock",
            title: "Agile ZTSecOps Implementation",
            paragraph1:
                "In a landscape where agility is key, VCSG pioneers the convergence of Zero Trust principles with the agility of DevSecOps practices.",
            colTitle1: "Semantic Context Rapid Deployment",
            colContent1:
                "VCSG's SecDevOps teams ensure the swift integration of the AI/ML Platform and Policy Enforcement Points into the System Development Cycle.",
            colTitle2: "CI/CD Pipelines",
            colContent2:
                " The implementation of Zero Trust Security Operations (ZTSecOps) is woven seamlessly into CI/CD pipelines. This includes security checks at every stage, such as SAST, DAST, container vulnerability scanning, IaC scanning, and security policy scanning.",
            colTitle3: "Fostering a Zero Trust Culture",
            colContent3:
                "Beyond technology, VCSG is committed to fostering a security culture among stakeholders, ensuring that security becomes an inherent part of the development and deployment process.",
        },
    ];

    const ctaContent = [
        {
            img: "./images/zero-trust-images/CTAHero.jpg",
            title: "Ready To Unlock Zero Trust?",
            subtitle: "Get in touch with us and we will give you the key!",
        },
    ];

    return (
        <div>
            <NavBar />
            {/* Hero Section */}
            <LeftAlignHero
                title={heroData.title}
                subtitle={heroData.subtitle}
                img={heroData.img}
                imgAlt={heroData.imgAlt}
                button={true}
            />
            {/* End Hero Section */}
            {/* Start Carousel Section */}
            <div className='grandParent'>
                <div className='carouselParent'>
                    <h1 className='text-center'>Our Approach</h1>
                    <hr className='dividerBlack'></hr>
                    <Carousel interval={null}>
                        {slidesOneTwoContent.map((item) => (
                            <Carousel.Item>
                                <Slide
                                    img={item.img}
                                    alt={item.alt}
                                    title={item.title}
                                    paragraph1={item.paragraph1}
                                    paragraph2={item.paragraph2}
                                />
                            </Carousel.Item>
                        ))}
                        {slidesThreeFourContent.map((item) => (
                            <Carousel.Item>
                                <Slide
                                    img={item.img}
                                    alt={item.alt}
                                    title={item.title}
                                    paragraph1={item.paragraph1}
                                    hasCols={"hasCols"}
                                    col1={
                                        <Column
                                            colTitle={item.colTitle1}
                                            colContent={item.colContent1}
                                        />
                                    }
                                    col2={
                                        <Column
                                            colTitle={item.colTitle2}
                                            colContent={item.colContent2}
                                        />
                                    }
                                    col3={
                                        <Column
                                            colTitle={item.colTitle3}
                                            colContent={item.colContent3}
                                        />
                                    }
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
            {/* End Carousel Section */}
            {/* Start CTA Section */}
            <CTA
                flex={"rightCTA"}
                img={ctaContent[0].img}
                title={ctaContent[0].title}
                subtitle={ctaContent[0].subtitle}
                gradient={"gradientRight"}
                alignment={"alignRight"}
                href='/calendly'
            />
            {/* End CTA Section */}
            <Footer />
        </div>
    );
}

function Slide(props) {
    return (
        <div className='carouselSlide slide1'>
            <div
                className='slideImage'
                style={{ backgroundImage: `url(${props.img})` }}
            ></div>
            <div className='slideContent'>
                <h2>{props.title}</h2>
                <hr className='dividerWhite' />
                <h4>{props.paragraph1}</h4>
                <div className='secondSection'>
                    <h4 className={props.hasCols}>{props.paragraph2}</h4>
                    {props.col1}
                    {props.col2}
                    {props.col3}
                </div>
            </div>
        </div>
    );
}

function Column(props) {
    return (
        <div>
            <h4>{props.colTitle}</h4>
            <hr className='colDivider' />
            <p>{props.colContent}</p>
        </div>
    );
}
