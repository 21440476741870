import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import "./WomenForm.css";

export default function WomenForm() {
  return (
    <div className="mainBox">
      <form>
      <h2 class="text-center">Service Women in Cybersecurity Initiative</h2>
      <br />
      <br />
        <div className="row">
          <div className="col">
            <label for="fname">First Name: </label>
            <input
              type="text"
              id="fname"
              name="fname"
              placeholder="First Name"
              required
            ></input>
          </div>
          <div className="col">
            <label for="lname">Last Name: </label>
            <input
              type="text"
              id="lname"
              name="lname"
              placeholder="Last Name"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="phoneNumber">Phone Number: </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone Number"
            ></input>
          </div>
          <div className="col">
            <label for="email">Email: </label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="branch">Branch: </label>
            <select name="branch" id="branch">
              <option value="none" selected disabled hidden>
                Select
              </option>
              <option value="Army">Army</option>
              <option value="USMC">USMC</option>
              <option value="Navy">Navy</option>
              <option value="USAF">USAF</option>
              <option value="Space Force">Space Force</option>
              <option value="Coast Guard">Coast Guard</option>
              <option value="Air Guard">Air Guard</option>
              <option value="Army Res">Army res</option>
              <option value="USMC Res">USMC res</option>
              <option value="Navy Res">Navy res</option>
              <option value="Coast Guard Res">Coast Guard res</option>
            </select>
          </div>
          <div className="col">
            <label for="militaryStatus">Military Status: </label>
            <select name="militaryStatus" id="militaryStatus">
              <option value="none" selected disabled hidden>
                Select
              </option>
              <option value="Active Duty">Active Duty</option>
              <option value="Guard/Reserver">Guard/Reserve</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="fulltime">Serving full-time on Active Duty?</label>
            <select name="fulltime" id="fulltime">
              <option value="none" selected disabled hidden>
                Select
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="col">
            <label for="installation">Military Installation:</label>
            <input
              type="text"
              id="installation"
              name="installation"
              placeholder="Installation"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="grade">Grade:</label>
            <input
              type="text"
              id="grade"
              name="grade"
              placeholder="Grade"
            ></input>
          </div>
          <div className="col">
            <label for="AFSC/MOS/NEC">AFSC/MOS/NEC:</label>
            <input
              type="text"
              id="AFSC/MOS/NEC"
              name="AFSC/MOS/NEC"
              placeholder="AFSC/MOS/NEC"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="separation">Date of Separation:</label>
            <input
              type="date"
              id="separation"
              name="separation"
              value=" "
              min="2018-01-01"
              max="2030-12-31"
            ></input>
          </div>
          <div className="col">
            <label for="terminal">Terminal Leave (if applicable):</label>
            <input
              type="date"
              id="separation"
              name="separation"
              value=""
              min="2018-01-01"
              max="2030-12-31"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="education">Highest Education:</label>
            <select>
              <option value="none" selected disabled hidden>
                Select
              </option>
              <option val="H.S. Diploma">H.S. Diploma</option>
              <option val="Associates Degree">Associates Degree</option>
              <option val="Bachelors Degree">Bachelors Degree</option>
              <option val="Masters Degree">Masters Degree</option>
              <option val="Doctoral Degree">Doctoral Degree</option>
            </select>
          </div>
          <div className="col">
            <label for="study">Concentration of Study:</label>
            <input
              type="text"
              id="study"
              name="study"
              placeholder="Concentration of Study"
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              For purposes of complying with Federal Export Control Laws, are
              you a U.S. Citizen?
            </p>
            <div className="selection">
              <div className="radio-item">
                <input
                  type="radio"
                  id="citizen_yes"
                  name="citizen"
                  value="Yes"
                ></input>
                <label for="citizen_yes">Yes</label>
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  id="citizen_no"
                  name="citizen"
                  value="No"
                ></input>
                <label for="citizen_no">No</label>
              </div>
            </div>
          </div>
          <div className="col">
            <label for="clearance">Clearance Level</label>
            <select>
              <option value="none" selected disabled hidden>
                Select
              </option>
              <option value="None">None</option>
              <option value="Public Trust">Public Trust</option>
              <option value="Secret">Secret</option>
              <option value="Top Secret">Top Secret</option>
              <option value="Top Secret/SCI">Top Secret/SCI</option>
              <option value="Top Secret/CI Poly">Top Secret/CI Poly</option>
              <option value="Top Secret/Full Scope Poly">
                Top Secret/Full Scope Poly
              </option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Current Status</p>
            <div className="selection">
              <div className="radio-item">
                <input
                  type="radio"
                  id="status_active"
                  name="status"
                  value="Yes"
                ></input>
                <label for="status_active">Active</label>
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  id="status_inactive"
                  name="status"
                  value="No"
                ></input>
                <label for="status_inactive">Inactive</label>
              </div>
            </div>
          </div>
          <div className="col">
            <label for="clearance">Date of last investigation if known</label>
            <input type="text" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Have you used your clearance within the past 2 years?</p>
            <div className="selection">
              <div className="radio-item">
                <input
                  type="radio"
                  id="clearance_used_yes"
                  name="clearance"
                  value="Yes"
                ></input>
                <label for="clearance_used_yes">Yes</label>
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  id="clearance_used_no"
                  name="clearance"
                  value="No"
                ></input>
                <label for="clearance_used_no">No</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Are you interested in a post-enlistment internship?</p>
            <div className="selection">
              <div className="radio-item">
                <input
                  type="radio"
                  id="internship_yes"
                  name="internship"
                  value="Yes"
                ></input>
                <label for="internship_yes">Yes</label>
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  id="internship_no"
                  name="internship"
                  value="No"
                ></input>
                <label for="internship_no">No</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              After your enlistment, are you open to the opportunity for an
              internship with VCSG?
            </p>
            <div className="selection">
              <div className="radio-item">
                <input
                  type="radio"
                  id="vcsg_internship_yes"
                  name="vcsg_internship"
                  value="Yes"
                ></input>
                <label for="vcsg_internship_yes">Yes</label>
              </div>
              <div className="radio-item">
                <input
                  type="radio"
                  id="vcsg_internship_no"
                  name="vcsg_internship"
                  value="No"
                ></input>
                <label for="vcsg_internship_no">No</label>
              </div>
            </div>
          </div>
          <div className="col">
            <label for="desired_vcsg_internship">
              Date of desired internship?
            </label>
            <input
              type="date"
              id="desired_vcsg_internship"
              name="desired_vcsg_internship"
              value=" "
              min="2018-01-01"
              max="2030-12-31"
            ></input>
          </div>
        </div>
        <div className="row">
          <label for="additional">
            Notes, special skills, certifications, other details
          </label>
          <textarea
            name="additional"
            id="additional"
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div className="row">
          <span>
            <input type="checkbox" name="terms" id="terms"/>
            <label for="terms">
              I agree to terms & conditions provided by the company. By
              providing my phone number, I agree to receive text messages from
              the business.
            </label>
          </span>
        </div>
        <input type="submit" />
      </form>
    </div>
  );
}