import "./assets/css/navbar.css";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { LinkContainer } from "react-router-bootstrap";

export function NavBar() {
   const [display, setDisplay] = useState(false);

   useEffect(() => {
      const handleResize = () => {
         const burger = document.querySelector(".navbar-toggler");
         const burgerDisplay = window.getComputedStyle(burger).display;
         let navButtons = Array.from(document.getElementsByClassName("nav-link"));
         if (burgerDisplay === "block") {
         // If burger menu is not displayed, toggle the display based on the state
         display ? document.getElementById("nav-button").style.display = "block" :
         document.getElementById("nav-button").style.display = "none";
            
         } else {
            document.getElementById("nav-button").style.display = "block";
 
         }
      };

      window.addEventListener("resize", handleResize);
      handleResize(); // Call handleResize initially to set the initial display state

      // Cleanup function to remove event listener when component unmounts
      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, [display]); // Include display in dependency array to re-run effect when display changes

   return (
      <>
         <Navbar expand='lg' className='nav'>
            <Container>
               <LinkContainer to='/'>
                  <Image
                     className="logo-image"
                     src='./images/logos/VCSG_logo-w-text.png'
                     alt='Logo'
                  />
               </LinkContainer>
               <Navbar.Toggle
                  onClick={() => setDisplay(!display)}
                  aria-controls='basic-navbar-nav'
               />
               <Navbar.Collapse id='basic-navbar-nav'>
                  <Nav className='m-auto'>
                     <LinkContainer to='/'>
                        <Nav.Link>Home</Nav.Link>
                     </LinkContainer>

                     <LinkContainer to='/services' as='div'>
                        <Nav.Link>Services</Nav.Link>
                     </LinkContainer>

                     <LinkContainer to='/about' as='div'>
                        <Nav.Link>About</Nav.Link>
                     </LinkContainer>

                     <LinkContainer to='/blog' as='div'>
                        <Nav.Link>Blog</Nav.Link>
                     </LinkContainer>

                     <LinkContainer to='/contact' as='div'>
                        <Nav.Link>Contact</Nav.Link>
                     </LinkContainer>
                  </Nav>
               </Navbar.Collapse>
               <a href="/calendly">
                  <Button
                     variant='btn-primary btn-dark'
                     id="nav-button"
                  >
                     Book an Appointment
                  </Button>{" "}
               </a>
            </Container>
         </Navbar>
         <hr className="margin" />
      </>
   );
}
