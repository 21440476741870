import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import "./government.css";
import Flag from "../../assets/background_image/flag.jpg";
import { Footer } from "../../footer.jsx";
import {NavBar} from "../../navBar";
import Logo from "../../assets/images/logos/VCSG_logo.png";
import { useMediaQuery } from 'react-responsive'


export default function Government() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const myStyles = {
    fontWeight: "bold",
  };

  const infoContent = [
    {
      title: `KEY DIFFERENTIATORS`,
      listTitle: [
        `GSA HACS SIN54151`,
        `Service-Disabled Veteran Owned Small Business (SDVOSB)`,
        `Federal Cybersecurity Professional Program`,
        `ISC2 Official Training Provider (OTP) `,
        `Cyber AB CMMC C3PAOz`
      ],
      list: [
        `Highly Adaptive Cybersecurity Services`,
        ``,
        `DoD approved SkillBridge Program to recruit, train, certify, and place active-duty US Service Members into the Federal Cybersecurity Workforce.`,
        `CISSP Training meeting DoD 8570.01 Level III Information Assurance Training`,
        ``
      ],
      logo:[
        "./images/logos/state-of-ga.png",
        "./images/logos/canon.png",
        "./images/logos/security-agency.png",
      ]
    },
    {
      title: `CORE COMPETENCIES`,
      listTitle: [
        `Cybersecurity & Information Assurance`,
        `Federal Zero Trust Architecture`,
        `Federal Cloud Migration`,
        `Security Engineering`,
        `Security Assessment & Authorization/Certification and Accreditation`,
        `Cybersecurity Strategy, Governance & Program Management (PMO)s`,
        `NIST Risk Management Framework (RMF)`,
        `DFARS, NIST SP800-171, with CMMC Audit Capability`,
        `CMMC Certification (CMMC)`,
        `FISMA/NIST Certification & Accreditation (C&A)/Security Assessment & Authorization (SA&A)`,
        `Forensics & e-Discovery`
      ],
      logo: [
      "./images/logos/VA.png",
      "./images/logos/DTCC.png",
      "./images/logos/OOPM.png",
  ]
    }
  ]

  const contact = {
    "":"Paul Gozaloff",
    Email: "paul@veteranscybersecurity.com",
    Phone: "(754) 423-7352",
    Address: "4157 SW 54th Street, Plantation, FL 33317"
  }

  const contactLogo = [
    "./images/logos/SDVOSB-logo-color-768x768.png",
    "./images/logos/SAM.png",
    "./images/logos/GSA.png"
  ]

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1020px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 590px)' })
  return (
    <>
    <NavBar />
      <section className="heroImage ">
        <div className="bg-white-06 flagImg">
          <img id="flagImage" src={Flag}/>
          <Container className=" text-center">
            <Row>
              <Col className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 heroTextGov heroText">
                <h1 className="fw-bold m-0 governmentHeroText"> Government</h1>
                
              </Col>
            </Row>
            {/*end row*/}
          </Container>
          {/*end container*/}
        </div>
      </section>

      <div className="mainContainerGovernment">
        <div className="container-xxl my-5">
         {isTabletOrMobile && !isMobile && 
            <div className="capabilities-statement">
              <div className="capabilities-statement-container">
                    <img id="logo-VCSG" src={Logo}/>
                    <div className="middle-cap">
                        <h2>Federal Capability Statement</h2>
                        <h3>Veterans Cybersecurity Group, Inc</h3>
                        <p>Service-Disabled Veteran Owned Small Business (SDVOSB)</p> 
                    </div>
                    <div className="last-cap">
                      <p>CVE Verification ID: 336799</p>
                      <p>UEI: <b>VEF3G9NKPHL7</b></p>
                      <p>CAGE Code: <b>8RRB3</b></p>
                    </div>
                </div>
                  <p>NAICS CODES: 541990, 519190, 541330, 541511, 541512, 541519,541611, 541618, 541690, 561499, 561621, 611420, 611430, 611710</p>
              </div>}
              {!isTabletOrMobile && <div className="capabilities-statement">
                  <img id="logo-VCSG" src={Logo}/>
                  <div className="middle-cap">
                      <h2>Federal Capability Statement</h2>
                      <h3>Veterans Cybersecurity Group, Inc</h3>
                      <p>Service-Disabled Veteran Owned Small Business (SDVOSB)</p> 
                      <p>NAICS CODES: 541990, 519190, 541330, 541511, 541512, 541519,541611, 541618, 541690, 561499, 561621, 611420, 611430, 611710</p>
                  </div>
                  <div className="last-cap">
                    <p>CVE Verification ID: 336799</p>
                    <p>UEI: <b>VEF3G9NKPHL7</b></p>
                    <p>CAGE Code: <b>8RRB3</b></p>
                  </div>
              </div>}
              { isMobile && 
                <div className="capabilities-statement">
                  <div className="capabilities-statement-container">
                        <img id="logo-VCSG" src={Logo}/>
                        <div className="middle-cap">
                            <h2>Federal Capability Statement</h2>
                            <h3>Veterans Cybersecurity Group, Inc</h3>
                            <p>Service-Disabled Veteran Owned Small Business (SDVOSB)</p> 
                            <br/>
                            <p>CVE Verification ID: 336799</p>
                            <p>UEI: <b>VEF3G9NKPHL7</b></p>
                            <p>CAGE Code: <b>8RRB3</b></p>
                            <br/>
                            <p>NAICS CODES: 541990, 519190, 541330, 541511, 541512, 541519,541611, 541618, 541690, 561499, 561621, 611420, 611430, 611710</p>
                        </div>
                  </div>
                </div>}
              
          <br/>
          <div className="who-we-are">
            <h2>Who We Are</h2>
            <div className="separator"></div>
            <p>Veterans Cybersecurity Group, Inc (VCSG) is a cybersecurity consulting firm fulfilling the cybersecurity and information assurance needs of Federal Agencies and US Government Contractors in accordance with Federal Law. We have provided cybersecurity services for organizations like the <b>Dept. of Veteran Affairs, US Office of Personnel Management, Canon</b> and the <b>Depository Trust & Clearing Corporation</b>. Our consulting engagements include Federal Agency and Contractor Compliance while utilizing US Military members transitioning into the Federal Cybersecurity Workforce.</p>
          </div>
          <br/>
          <div className="gov-contact">
            <h2>GOVERNMENT POINT OF CONTACT</h2>
            <div className="separator"></div>
            <div className="gov-contact-sub">
            <div className="gov-contact-info"> 
              {Object.entries(contact).map(([key, value], index) => (
                <p key={index}>
                  {key ? <b>{key}: </b> : null}
                  {value}
                </p>
              ))}
            </div> 
            <div className="cont-logo">
              {contactLogo.map((each,index)=><img key={index} src={each} alt={`Logo ${index + 1}`}></img>)}
            </div>
            </div>
          </div>
          <div className="gov-container">
            {infoContent.map((content,index) => (
            <div className="gov-con">
              <div key={index} className={`gov-container-box ${index % 2 ? "container-blue" : "container-grey"}`}>
                <p style={myStyles}>{content.title}</p>
                <div className="separator"></div>
                <div>
                  {content.listTitle.map((list,idx)=> (
                      <li key={idx} style={myStyles}>
                        {list}
                      {content.list ? content.list.map((list,subIdx)=> (idx === subIdx ? <ul key={idx} >{list}</ul>:"")): ""}
                        </li>))}
                  </div>
         
               </div>
              <div className="gov-container-logo">
                <div key={index} className="logo-group">
                  {content.logo.map((logo,idx)=> (
                    <img key={idx} src={logo} alt={`content.logo ${idx + 1}`}></img>
                ))}
                </div>
               </div>
               </div>
              
            ))}
          </div>








          <a href="./download/capabilities-statement.pdf" download>
            <button className="btn btn-dark">Download PDF</button>
          </a>
          <hr />
        </div>
        <div>
          <div className="container-xxl my-5">
            <h1 className="heading text-center">
               Federal Cybersecurity Professional Program (SkillBridge) 
            </h1>
            <img className="government-skillbridge" src="/images/government/skillbridgeHero.png" alt="Skillbridge"/>
            <p>
              The VCSG Federal Cybersecurity Professional Program, a DoD
              SkillBridge-approved initiative, provides active-duty US Service
              members with free training, certification, mentorship, and
              employment opportunities during their last 180 days of enlistment.
              With a focus on cultivating a talent pipeline for federal
              cybersecurity, the program, coupled with CISSP certification,
              equips service members for transition into civilian roles,
              requiring IAT Level III certification for DoD work. Structured in
              four phases, including federal baseline accreditation, specialty
              training, and job placement, the program ensures comprehensive
              preparation for civilian cybersecurity positions.
            </p>
            <a href="./download/Federal_Cybersecurity_Professional_Program.pdf" download>
              <button className="btn btn-dark">Download PDF</button>
            </a>
            <hr />
          </div>
        </div>
        <div>
          <div className="container-xxl my-5">
            <h1 className="heading text-center">Federal Agency Offerings</h1>
            <p>
              VCSG specializes in comprehensive cybersecurity services aligned
              with the NIST Risk Management Framework, mandated by FISMA for
              federal agencies. Our offerings encompass certification,
              high-value asset protection, assessments, supply chain risk
              management, forensics, planning, training, FISMA reporting, and
              continuous diagnostics. Certified Information Systems Security
              Professional (CISSP) is the most globally recognized certification
              in the cybersecurity market. CISSP validates a cybersecurity
              professional’s deep technical and managerial knowledge and
              experience to effectively design, engineer and manage an
              organization’s overall security posture.
            </p>
            <hr />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
