
import React from "react";


export default function Partnership() {
  return (
    <>
      {/* temporary styling */}
      <section
        className="parnterHeroSection"
        style={{
          width: "100vw",
          height: "20vw",
          position: "relative",
        }}
      >
        <div
          className="partnerHeroImgDiv"
          style={{
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(to bottom, rgba(255, 255, 255, 0.5), white), url('/images/background_image/cissp-background.jpg')",
            backgroundSize: "100%",
          }}
        ></div>
        <div
          className="partnerContentHero"
          style={{
            position: "absolute",
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <h1 style={{ fontWeight: "600" }}>Partnerships with Federal</h1>
        </div>
      </section>
    </>
  );
}
