import { PortableText } from "@portabletext/react";
import "./Post.css";
import "./postResponsivness.css";
import { NavBar } from "../../navBar";
import { Footer } from "../../footer";
import { useLocation } from "react-router-dom";
import React from "react";

export default function Post() {
    const data = useLocation();
    const { post } = data.state;
    return (
        <>
            <NavBar />
            <div className='postContainer'>
                <div
                    className='postImgContainer'
                    style={{ backgroundImage: `url(${post.imageUrl})` }}
                ></div>
                <div className='postTitleContainer'>
                    <div className='tagContainer'>
                        <p>{post.categories[0].categoryName}</p>
                    </div>
                    <h1 className='postTitle'>{post.title}</h1>
                    <hr />
                </div>
                <div className='postContentContainer'>
                    <PortableText value={post.content} />
                </div>
            </div>
            <Footer />
        </>
    );
}
