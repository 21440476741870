import CTA from "../../components/cta/CTA";
import React, { useEffect } from "react";
import "./FederalContracts.css";
import "./fedContractsResponsiveness.css";
import LeftAlignHero from "../../components/LeftAlignHero/LeftAlignHero";
import { Footer } from "../../footer.jsx";
import { NavBar } from "../../navBar";

export default function FederalContract() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const heroData = {
        title: "Next-Level Security for Federal Contractors",
        subtitle: "Partner with VCSG today!",
        img: "./images/fed-contracts-images/fedContractHero2.jpg",
        imgAlt: "Cyber Security Thumbprint Scanner",
    };

    const cardData = [
        {
            logo: "./images/logos/skillbridge_logo.png",
            logoName: "skillBridge",
            title: "TS/SCI Staff Augmentation",
            content:
                "The VCSG Federal Cybersecurity Professional Program, approved under DoD SkillBridge, offers active-duty US Service members free training, certification, mentorship, and employment opportunities in their last 180 days of enlistment. Emphasizing a talent pipeline for federal cybersecurity, the program, with CISSP certification, prepares service members for civilian roles, meeting IAT Level III certification requirements for DoD work. Organized in four phases, including federal baseline accreditation, specialty training, and job placement, it ensures a comprehensive transition to civilian cybersecurity positions.",
        },
        {
            logo: "./images/logos/SDVOSB-logo-color-768x768.png",
            logoName: "svdosb",
            title: "Service-Disabled Veteran Owned Small Business",
            content:
                "VCSG, certified by the Veterans Administration as a Service-Disabled Veteran Owned Small Business (SDVOSB, CVE Verification ID:33679), aligns with the Veterans Entrepreneurship and Small Business Development Act of 1999. The federal commitment to award at least three percent of contracts to SDVOSBs enhances opportunities for companies partnering with VCSG, contributing to their success in federal contract competitions.",
        },
        {
            logo: "/images/logos/CMMC-logo-800x767.png",
            logoName: "cmmc",
            title: "Contractor Partnering & Joint Ventures",
            content:
                "The U.S. Department of Defense (DoD) is instituting the Cybersecurity Maturity Model Certification (CMMC) to assess and enhance the cybersecurity of its supply chain. With three maturity levels, CMMC imposes evolving requirements on processes and practices. Anticipated to become a DoD contractual prerequisite and award condition, CMMC will affect all federal contractors providing goods or services to the DoD, pending the completion of rulemaking expected between late 2022 to late 2023.",
        },
        {
            logo: "./images/logos/C3PAO_logo.png",
            logoName: "cmmc",
            title: "Certified 3rd Party Audit Company (CMMC- C3APO)",
            content:
                "Veterans Cybersecurity Group is an authorized as a Certified 3rd Party Assessment Organization (C3APO) that can perform the DoD mandated audits. As CMMC-C3APO we are authorized to perform CMMC Certification assessments and are listed in the official CMMC Marketplace. Alternatively, we also perform CMMC audit preparedness engagements utilizing our CMMC Registered Professionals.",
        },
    ];

    const ctaContent = [
        {
            img: "./images/fed-contracts-images/fedContractCTA.jpg",
            title: "VCSG: Your Federal Contracting Partner for Success!",
            subtitle:
                "Navigate the world of federal contracts seamlessly with our expertise in CMMC, SDVOSB, and cybersecurity. Let's talk!",
        },
    ];

    return (
        <div>
            <NavBar />
            {/* Hero Section */}
            <LeftAlignHero
                title={heroData.title}
                subtitle={heroData.subtitle}
                img={heroData.img}
                imgAlt={heroData.imgAlt}
                button={true}
            />
            {/* End Hero Section */}
            {/* Start Logo Card Section */}
            <div className='logoCardsContainer'>
                <div className='logoCards'>
                    {cardData.slice(0, 2).map((item) => (
                        <LogoCard
                            logo={item.logo}
                            logoName={item.logoName}
                            title={item.title}
                            content={item.content}
                        />
                    ))}
                </div>
                {/* Start Large Logo Section */}
                <div className='largeSection'>
                    <div className='parent'>
                        <hr className='' />
                        <div className='contentRow'>
                            <div className='largeLogoContainer'>
                                <img
                                    className='largeLogo'
                                    src='./images/logos/CMMC_AB_logo .png'
                                ></img>
                            </div>
                            <div className=''>
                                <h2>VCSG’s CMMC Offerings</h2>
                                <p className=''>
                                    Veterans Cybersecurity Group as a CMMC AB
                                    authorized C3PAO is available to perform the
                                    actual DoD mandated CMMC Certification.
                                    Alternatively, for companies preparing for
                                    certification VCSG certified consultants can
                                    assist in audit scope definition, CUI
                                    handling, and all preparation required for a
                                    successful audit.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Large Logo Section */}
                <div className='logoCards'>
                    {cardData.slice(2).map((item) => (
                        <LogoCard
                            logo={item.logo}
                            logoName={item.logoName}
                            title={item.title}
                            content={item.content}
                        />
                    ))}
                </div>
            </div>
            {/* End Logo Card Section */}
            {/* Start CTA Section */}
            <CTA
                flex={"left"}
                img={ctaContent[0].img}
                title={ctaContent[0].title}
                subtitle={ctaContent[0].subtitle}
                gradient={"gradientLeft"}
                alignment={"alignLeft"}
                href='/calendly'
            />
            {/* End CTA Section */}
            <Footer />
        </div>
    );
}

function LogoCard(props) {
    return (
        <div className='cardContainer'>
            <div className='topContainer'>
                <div className={"logoContainer " + props.logoName}>
                    <img className='logo' src={props.logo}></img>
                </div>
                <div className='titleContainer'>
                    <h5 className='title'>{props.title}</h5>
                </div>
            </div>
            <div className='contentContainer'>
                <p>{props.content}</p>
            </div>
        </div>
    );
}
