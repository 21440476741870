import { Button, NavItem } from "react-bootstrap";
import "./LeftAlignHero.css";
import React from "react";

export default function LeftAlignHero(props) {
    return (
        <div
            className='heroContainer'
            style={{ backgroundImage: `url(${props.img})` }}
        >
            <div className='overlayLeft'></div>
            <div className='heroContent'>
                <h1>{props.title}</h1>
                <h3>{props.subtitle}</h3>
                {props.button ? (
                    <a href='calendly'>
                        <Button className='btn-dark' size='lg' type='button'>
                            Book an Appointment
                        </Button>
                    </a>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
}
