import "./Calendly.css";
import LeftAlignHero from "../../components/LeftAlignHero/LeftAlignHero";
import "./responsiveCalendly.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Footer } from "../../../src/footer";
import { NavBar } from "../../navBar";

export default function Calendly() {
    const [calendlyData, setCalendlyData] = useState();
    const [canDisplay, setCanDisplay] = useState(false);
    const heroData = {
        title: "Custom Cybersecurity Consults",
        subtitle: "We look forward to meeting you!",
        img: "./images/calendly-images/heroBg.jpg",
        imgAlt: "Cyber Security Fingerprint Scanner",
    };

    useEffect(() => {
        axios
            .get(
                'https://z2v7up70.api.sanity.io/v1/data/query/production?query=*[_type == "calendly"] | {title, url, lengthOfMeeting}'
            )
            .then((res) => {
                setCalendlyData(res.data.result);
                setCanDisplay(true);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div>
            <NavBar />
            {/* Hero Section Starts */}
            <LeftAlignHero
                title={heroData.title}
                subtitle={heroData.subtitle}
                img={heroData.img}
                imgAlt={heroData.imgAlt}
                button={false}
            />
            {/* Hero Section Ends */}
            <div className='calendlySection'>
                <div className='calendlyTitle'>
                    <h2>What would you like to discuss?</h2>
                    <div className='hr'></div>
                </div>
                <div className='calendlyCards'>
                    {!canDisplay ? (
                        <p>Loading...</p>
                    ) : (
                        calendlyData.map((item) => {
                            return (
                                <CalendlyCard
                                    link={item.url}
                                    title={item.title}
                                    time={item.lengthOfMeeting}
                                    key={item.title}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
}

function CalendlyCard(props) {
    return (
        <a className='link' href={props.link}>
            <div className='calendlyCard'>
                <div className='descrContainer'>
                    <h4>{props.title}</h4>
                    <div className='hr'></div>
                    <h6>Paul Gozaloff</h6>
                </div>
                <div className='timeContainer'>
                    <img
                        className='timeBg'
                        src='./images/calendly-images/cardBg.jpg'
                    />
                    <h4 className='time'>{props.time} Minutes</h4>
                </div>
            </div>
        </a>
    );
}
