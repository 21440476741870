import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from "react";

export default function Hero ({heroImage,header1,header2, button}) {
    return (
        <div className="gradient-background">

    <section className="bg-white-06">
		<section 
			className="heroImageSkillbridge " 
			style={{backgroundImage:`linear-gradient(to top, white, rgba(255, 255, 255, 0.02)),url("${heroImage}")`}}
			>
        
            <Container className=" text-center">
                <Row>
                    <Col className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 heroText">
                        <h1 className="fw-bold m-0" > {header1} </h1>
                        <h2 className="fw-bold m-0" > {header2}</h2>
                        <>{button}</>
                    </Col>
                </Row>
                {/*end row*/}
            </Container>
            {/*end container*/}
        </section>
    </section>
    </div>
    )
};