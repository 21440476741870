//import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React from "react";
import Button from "react-bootstrap/Button";

export function Footer() {
  return (
    <footer className="main-footer">
      <section className=" bg-light">
        <Container className="center footerContainer">
          <Row>
            <h2 className="uppercase letter-spacing-1 consultant-heading">
              Get a Free Consulation!
            </h2>
            <p className="consultant">
              Get in touch with us.
              <br />
              Book a free 30 minute consultation.
              <br /> Start your new career today!{" "}
            </p>
            <section className="center appointmentButton">
              <a href="/calendly">
                <Button variant="btn-primary btn-dark ">
                  Book an Appointment
                </Button>{" "}
              </a>
            </section>
          </Row>
        </Container>
      </section>
    </footer>
  );
}
