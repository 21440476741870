import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/button.css";
import "./assets/css/global.css";
import { BrowserRouter } from "react-router-dom";


class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service or handle it as needed
    console.error(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

//BrowserRouter has to wrap around both NavBar and App to work
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter> 
        <App />
      </BrowserRouter>
    </ErrorBoundary>

  </React.StrictMode>
);
