import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react'; 
import Image from "react-bootstrap/Image"
export default function Tiles ({image, text, design}) {
    return (
        <>
        <Row className={`${design}`}>
        <Col sm className="order-md-2">
            <Image className="womenImage" src={`${image}`} alt="" fluid/>
        </Col>
        <Col sm className="order-md-1">
            <p className="womentText">{text}</p>
        </Col>
        </Row>
        </>
    )
}