import Container from 'react-bootstrap/Container';
import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Hero from "../../components/hero.jsx";
import "./women.css";
import aboutImage from "../../assets/background_image/cissp-background.jpg"
import Tiles from '../../components/tiles.jsx';
import picture1 from '../../assets/women_in_cybersecurity_images/Denisha.png'
import picture2 from '../../assets/women_in_cybersecurity_images/two_women_stare_at_screen.jpg'
import picture3 from '../../assets/women_in_cybersecurity_images/woman_coding.jpg'
import picture4 from '../../assets/women_in_cybersecurity_images/woman_point_at_monitor.jpg'
import picture5 from '../../assets/women_in_cybersecurity_images/woman_working.jpg'
import { Outlet, Link } from "react-router-dom";
import { Footer } from "../../footer.jsx";
import {NavBar} from "../../navBar";

export default function Women () {
    let text1 = 'Recently retired Chief Master Sergeant Denisha Ward Swanigan, the Command Chief Master Sergeant for the 412th Test Wing, Edwards Air Force Base (AFB), California. CMSgt Ward’s 20-year career in cybersecurity leadership offers a wealth of experience from cybersecurity operations, compliance, and training for the Air Force Special Operations Command (AFSOC) to Senior Advisor to the Wing Commander on the readiness and resilience of 19,500 active duty, and defense contractors at Edwards Airforce Base.Her leadership in this program involves mentoring, advocating for, and empowering US Service Woman pursuing careers in cybersecurity, which is vital for bridging gender gaps in the field.';
    let text2 = 'As we venture into this new era of digital advancement, the threats we face are not only growing in number but also in complexity. A homogenous workforce can no longer suffice to counteract these multifaceted challenges. Diversity, in thought and experience, becomes our most potent weapon. The experiences that our servicewomen bring from their time in the military—leadership, resilience, and a deep sense of duty—are precisely the attributes needed in the dynamic world of cybersecurity.';
    let text3 = 'ISC2’s Certified in Cybersecurity (CC) is a prestigious certification that offers global opportunities in cybersecurity without requiring prior experience. ISC2 is currently offering free training and exams as part of the "One Million Certified in Cybersecurity" initiative, aiming to bridge the cybersecurity workforce gap.';
    let text4 = 'The CC certification encompasses fundamental domains of cybersecurity, such as Security Principles, Business Continuity, and Network Security, preparing aspirants for various roles in cybersecurity. It provides a dynamic platform especially beneficial for IT professionals, career transitioners, and recent graduates.';
    let text5 = 'The CC certification enhances professional credibility, facilitating a career marked by respect and diverse opportunities. It prepares individuals for industry requirements and lays a foundation for pursuing advanced ISC2 certifications like the CISSP, fostering a successful career in cybersecurity.';
   
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <NavBar />
        <Hero heroImage={aboutImage} header1 = "Empowering Women in Cybersecurity: Breaking Barriers and Building a Secure Future" button = {<Link to="/womenform">
                    <Button variant='btn-primary btn-dark formButton'>Become a Member</Button>
            </Link>}/>
        <hr />
        <br />
        <Container>
          
            <p className="womenMessageColor womentText">In the fast-paced digital era, the need for skilled cybersecurity professionals is at an all-time high. Our Service Woman in Cybersecurity Program caters to the specific needs of military personnel, facilitating a seamless transition from active service to civilian tech roles. Through rigorous ISC2 entry-level courses and impactful mentorship, we empower our servicewomen with the expertise and confidence to excel in cybersecurity. Led by experienced figures like Chief Master Sergeant Denisha Ward Swanigan, with two decades of cybersecurity leadership, this program is not just training; it's a movement.</p>
        </Container>

        <Container>        
            <Tiles image={picture1} text={text1} design={"left"}/>

            <Tiles image={picture2} text={text2} design={"right"}/>

            <Tiles image={picture3} text={text3} design={"left"}/>

            <Tiles image={picture4} text={text4} design={"right"}/>

            <Tiles image={picture5} text={text5} design={"left"}/>
        </Container>
        <Footer/>
        </>
    )
    }