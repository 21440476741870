import LeftAlignHero from "../../components/LeftAlignHero/LeftAlignHero";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import "./Contact.css";
import { FaRegEnvelope } from "react-icons/fa";
import { IconContext } from "react-icons";
import { LuPhone } from "react-icons/lu";
import { BsPinMap } from "react-icons/bs";
import { FaRegCalendar } from "react-icons/fa";
import "./responsiveContact.css";
import { NavBar } from "../../navBar";
import axios from "axios";
import React, {useEffect, useState} from "react";

export default function Contact() {
    const [activeData, setActiveData] = useState("");
    const [retResults, setRetResults] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const heroData = {
        title: "Connect with Confidence",
        subtitle: "Reach out to VCSG today!",
        img: "./images/contact-page-images/contactHero.jpg",
        imgAlt: "Holographic envelopes",
    };
    function handleChange(e){
        let value=e.target.value
        setActiveData(value)
        setIsDisabled(value == "")
    };

    useEffect(() => {
        axios
            .get(
                'https://z2v7up70.api.sanity.io/v1/data/query/production?query=*[_type == "contactForm"]{"topics": topics[]->{title, url}}'
            )
            .then((res) => {
                setRetResults(res.data.result[0].topics);
            })
            .catch((err) => console.log(err));
    },[]);

    return (
        <div>
            <NavBar />
            <LeftAlignHero
                img={heroData.img}
                imgAlt={heroData.imgAlt}
                title={heroData.title}
                subtitle={heroData.subtitle}
                button={true}
            />
            {/* Form section starts */}
            <div className='iconsContainer p-1'>
                <IconContext.Provider value={{ className: "icon" }}>
                    <div className='icons'>
                        <div>
                            <FaRegEnvelope size={36} />
                            <h6 className='fw-medium mb-1'>Email:</h6>
                            <p>Paul@VeteransCyberSecurity.com</p>
                        </div>
                        <div>
                            <LuPhone size={36} />
                            <h6 className='fw-medium mb-1'>Phone:</h6>
                            <p>(754) 423-7352</p>
                        </div>
                        <div>
                            <BsPinMap size={36} />
                            <h6 className='fw-medium mb-1'>Address:</h6>
                            <p>4157 SW 4th Street, Plantation, Fl  33317</p>
                        </div>
                        <div>
                            <FaRegCalendar size={36} />
                            <h6 className='fw-medium mb-1'>Calendly:</h6>
                            <a href='/calendly'>
                                <Button className='btn-dark btn-sm'>
                                    Book a Meeting
                                </Button>
                            </a>
                        </div>
                    </div>
                </IconContext.Provider>
            </div>
            <div className='formContainer'>
                <Form className='form'>
                    <Form.Group className='mb-3 w-100'>
                        <Form.Select value={activeData} className='m-1 p-3' required onChange={handleChange}>
                            <option selected value="">Pick a topic...</option>
                            {retResults.map((result) => 
                                <option value={result.url}>{result.title}</option>
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Button
                        className='btn-dark w-25 btn-lg contactSubmitButton mt-2~'
                        type='submit'
                        disabled={isDisabled}
                        onClick={() => {
                            window.open(activeData, "_blank")
                        }}
                    >
                        Contact Us
                    </Button>
                </Form>
            </div>
        </div>
    );
}
