import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../assets/css/services.css"; // Import your CSS file for styling
import Image from "react-bootstrap/Image";
import { Outlet, Link } from "react-router-dom";
import { Footer } from "../footer.jsx";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import {NavBar} from "../navBar.jsx"

function PartnershipModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="partnership-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title id="partnership-modal" className="modal-headers">
          <h5>Partnership with Federal Agency</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>
          Federal Contractors are encouraged to partner with VCSG to fill their
          cybersecurity workforce requirements. Veterans Cybersecurity Group was
          founded with the goal of addressing this Cybersecurity Workforce
          crisis by training, mentoring, and placing Active-Duty US Service
          Members as they transition into the civilian workforce. Our Federal
          Cybersecurity Professional Program has a team of recruiters that go
          out to US Military Bases presenting the program through the military
          Transition Assistance Program (TAP).
        </b>
      </Modal.Body>
      <Modal.Footer className="footer-buttons-layout">
        <Button className="btn-dark" onClick={props.onHide}>
          Close
        </Button>
        <a href="/calendly">
          <Button className="btn-dark">Book an Appointment</Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
}

function CMMCModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="CMMC-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title id="CMMC-modal" className="modal-headers">
          <h5>CMMC Certification</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="container">
          <div class="center"></div>
          <div>
            <b>
              {" "}
              Veterans Cybersecurity Group (VCSG) is currently a 
              C3PAO Candidate. Once VCSG completes its pending DIBCAC 
              assessment, it will transition to an Authorized C3PAO, 
              certified to conduct DoD-mandated CMMC Level 2 assessments. 
              Alternatively, we also perform CMMC audit
              preparedness engagements utilizing our CMMC Registered
              Professionals..
            </b>
          </div>
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-buttons-layout">
        <Button className="btn-dark" onClick={props.onHide}>
          Close
        </Button>
        <a href="/calendly">
          <Button className="btn-dark">Book an Appointment</Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
}

function FederalAgencyModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="agency-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title id="agency-modal" className="modal-title">
          <h5>Federal Contracting Partners </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="container">
          <div>
            <b>
              {" "}
              VCSG cybersecurity services are centered on, and follow guidance
              from, the National Institute of Standards and Technology (NIST)
              Risk Management Framework (RMF), the national standard mandated to
              federal agencies by FISMA to support cybersecurity risk
              management. These services include:
            </b>
          </div>
          <br />
          <div class="row">
            <ul>
              <li>
                Certification and Accreditation Process for Federal Information
                Systems
              </li>
              <li>High Value Asset (HVA) Protection</li>
              <li>Cybersecurity Assessments</li>
              <li>Cyber Supply Chain Risk Management (C-SCRM)</li>
              <li>Cyber Forensics</li>
              <li>Cybersecurity Planning</li>
              <li>Cybersecurity Training</li>
              <li>
                Federal Information Security Management Act (FISMA) Reporting
              </li>
              <li>Continuous Diagnostics and Mitigation</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-buttons-layout">
        <Button className="btn-dark" onClick={props.onHide}>
          Close
        </Button>
        <a href="/calendly">
          <Button className="btn-dark">Book an Appointment</Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
}

export default function Service() {
  const [CMMCModalShow, setCMMCModalShow] = React.useState(false);
  const [partnershipModalShow, setPartnershipModalShow] = React.useState(false);
  const [federalAgenyModalShow, setFederalAgenyModalShow] =
    React.useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the device screen is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 577); // Set a breakpoint for mobile devices
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();
    window.scrollTo(0, 0);
    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <NavBar />
      <section className="heroVideoSection">
        <div className="bg-white-06">
          {/* Conditional rendering based on the device type */}
          {isMobile ? (
            <img
              className="stillHeroImage"
              src="/images/services_images/earth.png"
              alt="Hero Image"
            />
          ) : (
            <video className="heroVideo" loop autoPlay muted>
              <source
                src="https://www.erasolawsdevtest.com/assets/videos/globe.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}

          <div className="overlay"></div>

          <Container className="text-center">
            <Row>
              <Col className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 heroText">
                <h1 className="fw-bold m-0">
                  DISCOVER THE SERVICES VETERANS CYBERSECURITY GROUP HAS TO
                  OFFER!
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <br />
      <Container fluid>

          <Row className=" g-4 g-lg-5 service-container">
            <Col md={4} lg={4} className="center content-selection-service">
              <Link to="/skillbridge">
                <div className="border p-4 content-container-service">
                  <Image
                    className="service-images"
                    src="/images/services_images/skillbridge.png"
                    alt="SkillBridge"
                  />
                  <h5 className="fw-medium mt-2">FEDERAL CYBERSECURITY SKILLBRIDGE PROGRAM</h5>
                  <br />
                  <p>
                  The VCSG Federal Cybersecurity Professional SkillBridge Program is a DoD-approved initiative that trains, certifies, mentors, and hires active-duty US Service Members in the last 180 days of their service for federal cybersecurity roles. It includes CISSP certification, meeting DoD IAT Level 3 requirements, and specialized training aligned with federal guidelines by NIST.  
                  </p>
                  <p>
                  The program now also incorporates training on Federal Zero Trust and Cloud programs enhancing participants' understanding of new Federal Modernization initiatives. 
                  </p>
                </div>
              </Link>
            </Col>
            <Col md={4} lg={4} className="center content-selection-service">
              <Link to="/government">
                <div className="border p-4 content-container-service">
                  <Image
                    className="service-images"
                    src="/images/services_images/government.png"
                    alt="Governemt"
                  />
                  <h5 className="fw-medium mt-2">GOVERNMENT</h5>
                  <br />
                  <p>
                    Veterans Cybersecurity Group, Inc (VCSG) is a cybersecurity
                    consulting firm fulfilling the cybersecurity and information
                    assurance needs of Federal Agencies and US Government
                    Contractors in accordance with Federal Law.{" "}
                  </p>
                </div>
              </Link>
            </Col>
            <Col md={4} lg={4} className="center content-selection-service">
              <Link to="/federalContract">
                <div className="border p-4 content-container-service">
                  <Image
                    className="service-images"
                    src="/images/services_images/federal_contracting.png"
                    alt="Federal Contract"
                  />
                  <h5 className="fw-medium mt-2">FEDERAL CONTRACTING PARTNERS</h5>
                  <br />
                  <p>VCSG seeks to build a strong team of federal contractors to partner with.  We offer a highly cleared Cybersecurity Workforce Pipeline, CMMC Consulting and Certification, , SDVOSB Set-Aside Status, and Joint Venture Opportunities.</p>
                </div>
              </Link>
            </Col>
            <Col md={4} lg={4} className="center content-selection-service">
              <Link to="/women">
                <div className="border p-4 content-container-service-row2">
                  <Image
                    className="service-images"
                    src="/images/services_images/lady.png"
                    alt="Women"
                  />
                  <h5 className="fw-medium mt-2">SERVICE WOMEN IN CYBERSECURITY INITATIVE (SWCSi)</h5>
                  <br />
                  <p>
                    The Service Woman in Cybersecurity Program provides ISC2
                    courses and mentorship for military women transitioning to
                    civilian tech roles, led by experts.
                  </p>
                </div>
              </Link>
            </Col>
            <Col md={4} lg={4} className="center content-selection-service">
              <Link to="/zeroTrust">
                <div className="border p-4 content-container-service-row2">
                  <Image
                    className="service-images"
                    src="/images/zero-trust-images/Hero.jpg"
                    alt="Zero"
                  />
                  <h5 className="fw-medium mt-2">ZERO TRUST</h5>
                  <br />
                  <p>
                    {" "}
                    We implement NIST SP800-207, strategic cloud migration, and
                    multi-factor authentication to build a resilient Zero Trust
                    architecture, ensuring compliance and adaptability.
                  </p>
                </div>
              </Link>
            </Col>
            {/* CMMC Certificate */}
            <Col md={4} lg={4} className="center content-selection-service">
              <div className="border p-4 content-container-service-row2">
                <Image
                  className="service-images"
                  src="/images/services_images/certificate.png"
                  alt="CMMC"
                />
                <h5 className="fw-medium mt-2">CMMC CERTIFICATION</h5>
                <br />
                <p>
                  Once VCSG successfully completes our own pending DIBCAC 
                  assessment we will transition from Candidate to 
                  Authorized C3PAO status for CMMC Level 2 certifications.
                </p>
                <Button
                  className="btn-dark cmmc-button"
                  variant="primary"
                  onClick={() => setCMMCModalShow(true)}
                >
                  Learn More
                </Button>

                <CMMCModal
                  show={CMMCModalShow}
                  onHide={() => setCMMCModalShow(false)}
                />
              </div>
            </Col>
            {/*Federal Ageny */}
            <Col md={4} lg={4} className="center content-selection-service">
              <div className="border p-4 content-container-service-row2">
                <Image
                  className="service-images"
                  src="/images/services_images/thinker.png"
                  alt="CISSP"
                />
                <h5 className="fw-medium mt-2">FEDERAL AGENCY SERVICES</h5>
                <br />
                <p>
                  VCSG offers cybersecurity solutions for federal agencies,
                  focusing on enhancing security, improving resilience,
                  protecting important information, and updating systems.
                </p>
                <Button
                  className="btn-dark federal-agency-button"
                  variant="primary"
                  onClick={() => setFederalAgenyModalShow(true)}
                >
                  Learn More
                </Button>

                <FederalAgencyModal
                  show={federalAgenyModalShow}
                  onHide={() => setFederalAgenyModalShow(false)}
                />
              </div>
            </Col>
            {/*Partnership*/}
            <Col md={4} lg={4} className="center content-selection-service">
              <div className="border p-4 content-container-service-row2">
                <Image
                  className="service-images"
                  src="/images/services_images/hand-shake.png"
                  alt="Partnership"
                />
                <h5 className="fw-medium mt-2">
                  PARTNERSHIP WITH FEDERAL AGENCY
                </h5>
                <p>
                  Federal Contractors, partner with VCSG for Cybersecurity
                  Workforce Solutions.
                </p>
                <Button
                  className="btn-dark partnership-button"
                  variant="primary"
                  onClick={() => setPartnershipModalShow(true)}
                >
                  Learn More
                </Button>

                <PartnershipModal
                  show={partnershipModalShow}
                  onHide={() => setPartnershipModalShow(false)}
                />
              </div>
            </Col>
          </Row>
       
      </Container>
      <Outlet />
      <Footer />
    </>
  );
}
