import Container from "react-bootstrap/Container";
import React from "react";
import "./about.css";
import Hero from "../../components/hero";
import aboutImage from "../../assets/background_image/laptopHero1.jpg";
import { Footer } from "../../../src/footer";
import {NavBar} from "../../navBar.jsx";

export default function About() {
  return (
    //Code goes in here.
    <>
      <NavBar />
      <Hero heroImage={aboutImage} header1 = "We're a Team of Professionals Who Love What We Do."/>
      <hr />
      <br />
      <section className="mainContainer">
        <Container>
          <div>
            <h2>VCSG'S Focus</h2>
            <p className="aboutFont">
              First is that a of a provider of Federal Information Assurance and
              Cybersecurity consulting services to federal agencies and their
              contractors. Supplemental to these cyber-risk engagements is our
              unique mentorship and apprenticeship program allowing active-duty
              US Military Members to transition to civilian life while still
              protecting the nations critical infrastructure as Civilian Federal
              Cybersecurity subject matter experts.
            </p>
          </div>
          <hr />
          <br />
          <div>
            <h2>The VCSG Opportunity and Challenge</h2>
            <p className="aboutFont">
              Veteran’s Cybersecurity Group (VCSG) is in the business of
              providing Cybersecurity services to Federal Government Agencies
              and federal contractors that must comply with significant new
              federal contractual cybersecurity assessment requirements. The
              volume of these new vendor certifications is expected to be
              significant as the DoD’s plan is for it’s over 300,000 contractors
              to have the requirement to complete a successful audit as a
              precontractual requirement prior to contract award. Each of these
              300,000 contracts must be certified in less than five years.
            </p>
          </div>
          <hr />
          <br />

          <Container className="boxLife">
            <div className="boxLife row1">
            <div className="tileParent">
              <div className="tileTop">
                <img className="tileTopIMGs" src="/images/logos/About/NISTSP800.png" alt="" />
                  <h5>VCSG CAPABILITIES</h5>
              </div>
                <p className ="bodyParagraph">
                VCSG specialty is Federal Cybersecurity and Information
                Assurance. We Provide these consulting services both to Federal
                Government Agencies with the goal of compliance with the Federal
                Information Security Management Act (FISMA).
                </p>                        
            </div>            
            <div className="tileParent">
              <div className="tileTop">
              <img className="tileTopIMGs" src="/images/logos/About/CMMC.png" alt="" />
                  <h5>
                  CMMC CERTIFIED THIRD PARTY ASSESSMENT ORGANIZATION-C3PA0</h5>
              </div>
                <p className ="bodyParagraph">
                Additionally, for Federal Contractors seeking the newly mandated DoD Cybersecurity Maturity Model Certification (CMCC) we offer both preaudit assessment services or as a CMMC AB C3APO we can perform an official certification (not both).
                </p>                        
            </div>
            </div>

            <div className="boxLife row2">
            <div className="tileParent">
              <div className="tileTop">
              <img className="tileTopIMGs" src="/images/logos/About/SDVOSB.png" alt=""  style={{backgroundColor: "white"}}/>
                  <h5>SERVICE-DISABLED, VETERAN-OWNED SMALL BUSINESS</h5>
              </div>
                <p className ="bodyParagraph">
                Veterans Cybersecurity Group, Inc. has been verified as a small business owned and controlled by veterans and service-disabled veterans company (CVE Verification ID: 33679).
                </p>                        
            </div>            
            <div className="tileParent">
              <div className="tileTop">
              <img className="tileTopIMGs" id="bottomrightimage" src="/images/logos/About/FISMA.png" alt="" style={{backgroundColor: "white"}}/>
                  <h5>EXPERTS IN FEDERAL CYBERSECURITY AND RISK MANAGEMENT</h5>
              </div>
                <p className ="bodyParagraph">
                VSCG Consultants specialize in cybersecurity standards, particularly NIST's guidelines. NIST, a non-regulatory agency under the U.S. Department of Commerce, focuses on developing security compliance standards for the Federal Government and organizations handling government data, mandated by FISMA and other regulations. VSCG's experts boast extensive experience in NIST's Risk Management Framework and the DoD CMMC Program based on SP800-171, ensuring adherence to detailed cybersecurity standards addressing interoperability and usability.
                </p>                        
            </div>
            </div>
          </Container>
        </Container>
      </section>
      <Footer />
      </>
  );
}
